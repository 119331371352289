<template>
<div class="p-5">

  <br>
  <br>
  <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
    <div class="row">
      <center>
        <p class="title">
            Historial de Referidos</p>
      </center>
    </div>
  </div>

  <div class="md:grid md:grid-cols-2 grid grid-cols-1 gap-10 mt-10" style=""> 
    <a :href="card.url" v-for="(card, i) in cards" :key="i" class="cardContainer">
      <div class="grid grid-cols-5 gap-4" style="">
        <div class="col-span-4" style="text-align:left;">
          <div>
            <p style="font-family:arial;font-weight:bold;margin-top:5px;font-size:40px">{{card.total}}</p>
            <p style="margin-top:5px;font-size:20px;color:gray">{{card.name}}</p>
          </div>
        </div>
        <div style="display:flex;justify-content:flex-end;">
          <div style="display:table;">
            <div style="display:table-cell;vertical-align:middle;">
            <img src="@/assets/Cuenta/flecha.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <br>
  <br>
  <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
    <div class="row">
      <center>
        <button class="" type="submit, text-decoration: none; width:150px" id="button_popups" v-on:click="redirect('/cuenta/referidos/referir-persona')">Referir personas</button>
      </center>
    </div>
  </div>
</div>
</template>



<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from '@/components/Cuenta/MenuLateral.vue'
import { getStats } from '@/api/references'
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
    data() {
      return {
        fullname:"",
        email:"",
        cellphone:"",
        user_id:"",
        isLoading: false,
        ccapi:process.env.VUE_APP_CCAPI,
        totalOpenLinks:0,
        totalReferrals:0,
        totalOpenInvitations:0,
        inProcessReferrals:0,
        totalRegisteredReferrals:0,
        totalIncompleteAdmissionRequest: 0,
        totalAdmissionRequestCreated: 0,
        totalAdmissionRequestUnderReview: 0,
        totalAdmissionRequestRejected: 0,
        cards: [
        ]
      }
    },
    name: 'Referrals',
    props: {
        msg: String
    },
    components: {
        MenuLateral
    },
    async beforeMount() {
      await this.loadInfo();
      await this.getStats();
    },
    methods:{
      Logout(){
        sessionStorage.clear();
        this.$router.push({path:"/"});
        // window.location.href = "/"
      },
      async loadInfo() {
        var auth = getTokenDecoden();
        this.user_id = auth["id"];
      },
      async getStats() {
        await getStats(
            sessionStorage.getItem("login"),
            this.user_id
        )
        .then((response) => {
          this.totalReferrals = response.data.response.stats.total_sent == null ? 0 : response.data.response.stats.total_sent;
          this.totalOpenInvitations = response.data.response.stats.total_sent == null ? 0 : response.data.response.stats.total_sent;
          this.inProcessReferrals = response.data.response.stats.total_incomplete_request == null ? 0 : response.data.response.stats.total_incomplete_request;
          this.totalRegisteredReferrals = response.data.response.stats.total_acepted == null ? 0 : response.data.response.stats.total_acepted;
          this.totalAdmissionRequestCreated = response.data.response.stats.total_accounts_created == null ? 0 : response.data.response.stats.total_accounts_created;
          this.totalIncompleteAdmissionRequest = response.data.response.stats.total_incomplete_request == null ? 0 : response.data.response.stats.total_incomplete_request;
          this.totalAdmissionRequestUnderReview = response.data.response.stats.total_completed_request == null ? 0 : response.data.response.stats.total_completed_request;
          this.totalAdmissionRequestRejected = response.data.response.stats.total_rejected == null ? 0 : response.data.response.stats.total_rejected;
          this.totalOpenLinks = response.data.response.stats.total_open_links == null ? 0 : response.data.response.stats.total_open_links;

          this.cards.push(
            { url: "/cuenta/referidos/historial/get_all_by_user_id", name: "Total de Referidos", total: this.totalReferrals },
            { url: "/cuenta/referidos/historial/get_by_open_links", name: "Referidos con invitación abierta", total: this.totalOpenLinks },
            { url: "/cuenta/referidos/historial/get_by_account_created", name: "Referidos con cuenta creada", total: this.totalAdmissionRequestCreated },
            { url: "/cuenta/referidos/historial/get_by_incomplete_admission_request", name: "Referidos con solicitud incompleta", total: this.totalIncompleteAdmissionRequest },
            { url: "/cuenta/referidos/historial/get_by_admission_request_under_review", name: "Referidos con solicitud en revisión", total: this.totalAdmissionRequestUnderReview },
            { url: "/cuenta/referidos/historial/get_by_admission_request_rejected", name: "Referidos con solicitud rechazada", total: this.totalAdmissionRequestRejected },
            { url: "/cuenta/referidos/historial/get_complete_request", name: "Referidos inscritos", total: this.totalRegisteredReferrals }
          )
        })
        .catch((error) => {
        });
      },
      redirect(url){
        window.localStorage.setItem("referenced", "");
        this.$router.push({path:`${url}`});
        // window.location.href = url;
      }
    }
}
</script>


<style>

#button_popups {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #feb72b;
  color: #fff;
  font-weight:bold;
  width: 250px;
  height: 40px;
  padding-top: 0%;
  font-size:16px;
}

.cardContainer {
  cursor: pointer;
  color:#000 !important;
  text-decoration:none !important;
  padding:10px 20px 10px 20px;
  background:#fff;
  border-width: 2px;
  border-style: solid;
  border-color:#810042;
  border-radius:7px;
  box-shadow: 0 0 5px #9ecaed;
}

</style>